// Object - page - top - fv

.fv {
    width: 100%;
    position: relative;
    &-bkimg {
        width: 100%;
    }
    &-front {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
    }
    &-swiper {
        width: 100%;
        position: absolute;
        top: 11.5rem;
        left: 0;
        .swiper-wrapper {
            -webkit-transition-timing-function: linear !important;
            -o-transition-timing-function: linear !important;
            transition-timing-function: linear !important;
        }
    }
    &-sns {
        display: flex;
        column-gap: 4.2rem;
        position: absolute;
        right: 19.8rem;
        bottom: 4.2rem;
        z-index: 11;
        .item {
            width: 4.2rem;
            height: 4.2rem;
            @include _hover {
                filter: brightness(115%);
            }
        }
    }
    @include _mq-down(md) {
        height: auto;
        &-swiper {
            width: 100%;
            position: static;
        }
    }
}
